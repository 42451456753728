
/**
 * Imports
 */

import {
	BrowserRouter as Router,	// Core required
	Route,						// Required for Agenda/Video/Chat
	Routes,						// Required for Agenda/Video/Chat
	useParams					// Required for Agenda/Video/Chat
} from "react-router-dom";

import { Helmet, HelmetData } from 'react-helmet-async';

//import Chat from './Chat.js'
//import Agenda from './Agenda.js'
import Video from './Video.js'

import './App.css';

/**
 * Init Helmet
 */

const helmetData = new HelmetData({});

/**
 * Chat loader
 */

/*
const ChatLoader = () => {
	const { lang } = useParams();
	return (
		<Chat lang={lang}/>
	);
};
*/

/**
 * Agenda loader
 */

/*
const AgendaLoader = () => {
	const { lang } = useParams();
	return (
		<Agenda lang={lang}/>
	);
};
*/

/**
 * Video component
 */

const VideoLoader = () => {
	const { lang } = useParams();
	return (
		<Video lang={lang}/>
	);
};

/**
 * App
 */

function App() {

	return (
	<>

	<Router>

		<Helmet helmetData={helmetData}>

		</Helmet>

		<div className="wp-block-cover alignfull has-background-dim">
			<img loading="lazy" width="2000" height="1125" className="wp-block-cover__image-background wp-image-5990" alt="" src="el/EL-Q2.png" data-object-fit="cover" srcset="el/EL-Q2.png 2000w, el/EL-Q2-300x169.png 300w, el/EL-Q2-1024x576.png 1024w, el/EL-Q2-768x432.png 768w, el/EL-Q2-1536x864.png 1536w" sizes="(max-width: 2000px) 100vw, 2000px" />
			<div className="wp-block-cover__inner-container">
				<div className="wp-block-image">
					<figure className="aligncenter size-large">
						<a href="/">
							<img loading="lazy" width="1024" height="118" src="el/EL-logo-1024x118.png" alt="" className="wp-image-5863" srcset="el/EL-logo-1024x118.png 1024w, el/EL-logo-300x35.png 300w, el/EL-logo-768x89.png 768w, el/EL-logo-1536x177.png 1536w, el/EL-logo-2048x236.png 2048w" sizes="(max-width: 1024px) 100vw, 1024px"/>
						</a>
					</figure>
				</div>
			</div>
		</div>

		<div className="App">

			<h1>Professional Solutions NA TownHall</h1>
			<h2>Wednesday, February 5th from 2:00 to 3:30 PM EDT (1:00 to 2:30 PM CDT)</h2>
			<h2>Mercredi le 5 février de 14:00 à 15:30 HAE (13:00 à 14:30 HAC)</h2>

			{
			//<Routes>
			//	<Route exact path="/" element={<AgendaLoader/>}/>
			//	<Route exact path="/:lang" element={<AgendaLoader/>}/>
			//</Routes>
			}

			<Routes>
				<Route exact path="/:lang" element={<VideoLoader/>}/>
			</Routes>

			{
			<div className="buttons">
				<h2>Choose a language for the event</h2>
				<h2>Choisissez une langue pour l'événement</h2>
				<a className="button" href="/en">English</a>
				<a className="button" href="/fr">Français</a>
			</div>
			}

			{
			//<Routes>
			//	<Route exact path="/:lang" element={<ChatLoader/>}/>
			//</Routes>
			}

			<div className="footer">

			</div>

		</div>

	</Router>

	</>
	);
}

export default App;

